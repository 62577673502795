<template>
  <div class="pic-comp">
    <div class="top">
      <div v-show="!readOnly" style="margin-right: 60px">
        <template v-if="!limited">
          <a-space>
            <a-dropdown :trigger="['click']">
              <a @click="(e) => e.preventDefault()"> 直接添加 </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button
                    :disabled="picList.length === 18"
                    type="link"
                    @click="
                      () => {
                        showPicStoreModal = !showPicStoreModal;
                        handleTrack('upload_image', {
                          source: '从成品库添加',
                        });
                      }
                    "
                    >从成品库添加</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-button
                    :disabled="picList.length === 18"
                    type="link"
                    @click="
                      () => {
                        showMyPicStoreModal = !showMyPicStoreModal;
                        handleTrack('upload_image', {
                          source: '从我的图库添加',
                        });
                      }
                    "
                    >从我的图库添加</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-button :disabled="picList.length === 18" type="link" @click="showPicUpload">本地上传</a-button>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <a-divider type="vertical" />
            <a-dropdown :trigger="['click']">
              <a @click="(e) => e.preventDefault()"> 作图 </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-tooltip placement="right" v-if="disTxt2Img">
                    <template slot="title"> 请先输入文案 </template>
                    <a-button :disabled="true" type="link" @click="$emit('handleTextToImg')">文转图</a-button>
                  </a-tooltip>
                  <a-button
                    v-else
                    :disabled="picList.length === 18"
                    type="link"
                    @click="
                      () => {
                        $emit('handleTextToImg');
                        handleTrack('text_to_image');
                      }
                    "
                    >文转图</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-button
                    :disabled="picList.length === 18"
                    type="link"
                    @click="
                      () => {
                        $emit('handlePageOpen', 'pintu');
                        handleTrack('puzzle');
                      }
                    "
                    >拼图</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-button :disabled="picList.length === 18" type="link" @click="$emit('handlePageOpen', 'ai')"
                    >AI作图</a-button
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-space>
        </template>
        <template v-if="limited">
          <a-button :disabled="picList.length === 18" type="link" @click="showPicUpload">本地上传</a-button>
        </template>
      </div>
      <div style="color: #929292">{{ picList.length }}/18</div>
      <a-tooltip placement="right">
        <template slot="title"> 更新编辑图片 </template>
        <a-button
          type="link"
          icon="redo"
          style="margin-left: 10px; line-height: 0px"
          v-show="showRefresh"
          @click="$emit('handlePicRefresh')"
        ></a-button>
      </a-tooltip>
    </div>

    <div class="bottom" v-show="!readOnly">
      <div v-show="picList.length" id="container" ref="sortableBox" style="display: flex">
        <div
          class="bottom-item"
          v-for="(value, index) in picList"
          :key="value.id"
          @mouseenter="value.showHover = true"
          @mouseleave="value.showHover = false"
        >
          <div class="item-wrap">
            <img class="bottom-item-img" :src="value.fileUrl" referrerpolicy="no-referrer" />
            <div class="hover-item" v-show="value.showHover">
              <div class="hover-item-top" @click="handleDel(value.id)">
                <a-icon type="close" style="color: #fff" />
              </div>
              <a-icon type="eye" class="icon" style="margin: 0 auto; color: #fff" @click="handlePicPreview(index)" />
              <div class="hover-item-bottom">
                <template v-if="!limited">
                  <div class="hover-bottom-item" @click="handlePicEdit(value)" v-if="value.templateId">编辑</div>
                  <div class="hover-bottom-item" @click="handleReplace(value.id)">替换</div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!picList.length" class="tips">
        <div class="tips_text">请至少添加一张图片</div>
        <div class="tips_row">
          <div class="tips_items">
            <p class="items_title">图片大小</p>
            <p class="items_text">支持上传的图片大小，</p>
            <p class="items_text">最大20MB的图片文件</p>
          </div>
          <div class="tips_items" style="margin: 0 5px">
            <p class="items_title">图片格式</p>
            <p class="items_text">支持上传的图片格式，</p>
            <p class="items_text">推荐使用png、jpg、jpeg</p>
          </div>
          <div class="tips_items">
            <p class="items_title">图片分辨率</p>
            <p class="items_text">推荐上传宽高比为3:4</p>
            <p class="items_text">分辨率不低于720*960的照片</p>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 5px" v-show="showTxt2ImgTip">
      <div style="padding-left: 15px">对图片不满意？试试其他方法吧~</div>
      <a-button type="link" @click="$emit('handleRedoText')">重新生成文案进行转图</a-button>
    </div>

    <!-- 查看笔记 -->
    <div class="bottom" v-show="readOnly">
      <div v-show="picList.length" style="display: flex">
        <div
          class="bottom-item"
          v-for="(value, index) in picList"
          :key="value.id"
          @mouseenter="value.showHover = true"
          @mouseleave="value.showHover = false"
        >
          <div class="item-wrap" style="cursor: pointer">
            <img
              referrerPolicy="no-referrer"
              class="bottom-item-img"
              :src="value.fileUrl"
              @click="handlePicPreview(index)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 图库弹窗 -->
    <PicStoreModal
      v-if="showPicStoreModal"
      @closeStoreModal="closeStoreModal"
      @addPic="handleAddPic"
      :isReplace="isReplace"
      :principalInfoList="principalInfoList"
      :vehicleSeriesInfoList="vehicleSeriesInfoList"
    />

    <!-- 我的图库弹窗 -->
    <MyPicStoreModal
      v-if="showMyPicStoreModal"
      :isReplace="isReplace"
      @addPic="handleAddPic"
      @closeStoreModal="closeStoreModal"
    />

    <!-- 标签库 -->
    <TagPicStoreModal
      v-if="tagPicStoreVisible"
      @closeStoreModal="closeRagStoreModal"
      @addPic="handleAddPic"
      :isReplace="isReplace"
      :chooseTagList="chooseTagList"
      :principalInfoList="principalInfoList"
      :vehicleSeriesInfoList="vehicleSeriesInfoList"
    ></TagPicStoreModal>

    <!-- 本地上传弹窗 -->
    <PicUploadModal
      v-if="showPicUploadModal"
      :principalInfoList="principalInfoList"
      :vehicleSeriesInfoList="vehicleSeriesInfoList"
      @addPic="handleAddPic"
      @closePicUpload="showPicUploadModal = false"
    />
  </div>
</template>

<script>
import PicStoreModal from './PicStoreModal.vue';
import TagPicStoreModal from './TagPicStoreModal';
import PicUploadModal from './PicUploadModal';
import MyPicStoreModal from './MyPicStoreModal.vue';
import Sortable from 'sortablejs';
import { cloneDeep, debounce } from 'lodash';
import { trackRequest } from '@/utils/track';

import { api as viewerApi } from 'v-viewer';

export default {
  props: {
    picList: {
      default: () => [],
    },
    principalInfoList: {
      default: () => [],
    },
    vehicleSeriesInfoList: {
      default: () => [],
    },
    chooseTagList: {
      default: () => [],
    },
    readOnly: {
      default: false,
    },
    disTxt2Img: {
      // 文转图按钮 - 文案为空时禁用
      default: true,
    },
    showTxt2ImgTip: {
      // 重新生成文转图文案提示
      default: false,
    },
    limited: {
      default: false,
    },
  },
  components: {
    PicStoreModal,
    PicUploadModal,
    TagPicStoreModal,
    MyPicStoreModal,
  },
  data() {
    return {
      showPicStoreModal: false,
      tagPicStoreVisible: false,
      isReplace: false,
      replaceId: '',
      showPicUploadModal: false,
      showMyPicStoreModal: false,
      showRefresh: false,
    };
  },
  watch: {
    picList(val) {
      if (val.length) {
        const has = val.filter((pic) => pic.templateId);
        this.showRefresh = has.length ? true : false;
      } else {
        this.showRefresh = false;
      }
    },
  },
  methods: {
    defineSortable() {
      this.$nextTick(() => {
        const el = this.$refs.sortableBox;
        const that = this;
        Sortable.create(el, {
          animation: 100, //拖拽动画过渡时间
          group: 'name',
          sort: true,
          onEnd: function (evt) {
            // 获取排序之后的data数据 同步至父组件
            const listData = cloneDeep(that.picList);
            listData.splice(evt.newIndex, 0, listData.splice(evt.oldIndex, 1)[0]);
            const newArray = listData.slice(0);
            that.$emit('updatePicList', newArray);
          },
        });
      });
    },
    handleHover(val) {
      val.showHover = true;
    },
    handleDel(id) {
      const list = JSON.parse(JSON.stringify(this.picList));
      const index = list.findIndex((val) => val.id === id);
      list.splice(index, 1);
      this.$emit('updatePicList', list);
    },
    handleAddPic(value) {
      let list = JSON.parse(JSON.stringify(this.picList));
      const newVal = value.map((val) => {
        return {
          id: Math.random(),
          showHover: false,
          fileUrl: val.fileUrl,
          fileId: val.fileId,
        };
      });
      if (this.isReplace) {
        // 替换逻辑
        const index = list.findIndex((value) => value.id === this.replaceId);
        list.splice(index, 1, ...newVal);
      } else {
        // 添加逻辑
        list.push(...newVal);
      }
      if (list.length > 18) {
        this.$message.info('最多只允许添加18张照片，超出部分未上传');
        list = list.splice(0, 18);
      }
      this.$emit('updatePicList', list);

      this.closeStoreModal();
    },
    closeStoreModal() {
      this.replaceId = '';
      this.isReplace = false;
      this.showPicStoreModal = false;
      this.showMyPicStoreModal = false;
    },
    //关闭标签库弹窗
    closeRagStoreModal() {
      this.tagPicStoreVisible = false;
      this.storeType = '';
    },
    handleReplace(id) {
      this.replaceId = id;
      this.isReplace = true;
      this.showPicStoreModal = true;
    },
    handlePicEdit({ picId, templateId }) {
      this.handleTrack('edit_image');
      window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}/home?picTempId=${templateId}&picId=${picId}`, '_blank');
      // window.open(`http://localhost:3000/home?picTempId=${templateId}&picId=${picId}`, '_blank');
    },
    showPicUpload() {
      this.replaceId = '';
      this.isReplace = false;
      this.showPicUploadModal = true;
      this.handleTrack('upload_image', {
        source: '本地上传',
      });
    },
    // 图片预览
    handlePicPreview(index) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.picList.map((val) => val.fileUrl),
      });
      $viewer.images.forEach((v) => {
        v.referrerPolicy = 'no-referrer';
      });
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  margin: 10px 0;
  // justify-content: space-between;
  align-items: center;
}

.bottom {
  overflow-y: auto;
  // height: 200px;

  .bottom-item {
    position: relative;
    margin: 0 0 10px 7px;

    .item-wrap {
      width: 150px;
      height: 200px;
      border: 1px solid #d8d8d8;
      border-radius: 5px;

      .bottom-item-img {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }

      .hover-item {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        max-height: 200px;
        border: 2px #cccccc solid;
        background-color: #0000004b;

        .hover-item-top {
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          border-radius: 0 0 0 5px;
          background-color: #444d54;
          cursor: pointer;
        }

        .hover-item-bottom {
          display: flex;
          justify-content: center;
          width: 100%;
          line-height: 30px;
          text-align: center;
          color: #fff;
          background-color: #33333345;
          cursor: pointer;

          .hover-bottom-item {
            flex: 1;
            text-align: center;
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }
}

.tips {
  width: 100%;
  background-color: #fff;

  .tips_text {
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #33333340;
    letter-spacing: 2px;
    font-weight: 600;
  }

  .tips_row {
    display: flex;
    margin-bottom: 20px;

    .tips_items {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 110px;
      line-height: 11px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #eaeaea;

      .items_title {
        font-weight: 600;
        font-size: 12px;
      }

      .items_text {
        font-weight: 500;
        font-size: 12px;
        color: #acacac;
      }
    }
  }
}
</style>
