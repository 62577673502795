<template>
  <div>
    <a-drawer :visible="visible" width="372" title="提取图片" @close="handleClose">
      <a-checkbox-group class="pic-list" v-model="selectedPicList">
        <div class="img-wrapper" v-for="(item, index) in picList" :key="index">
          <img :src="item.fileUrl" referrerpolicy="no-referrer" @click.stop="checkPic(index)" />
          <a-checkbox class="check-box" :value="index" @click.stop=""> </a-checkbox>
        </div>
      </a-checkbox-group>
      <div class="footer-wrapper">
        <a-button type="primary" @click="saveImageLib" :disabled="btnDisabled"> 存入成品库 </a-button>
        <a-button type="primary" @click="editPic" :disabled="btnDisabled"> 编辑图片 </a-button>
        <a-button type="primary" @click="combinePic" :disabled="btnDisabled"> 拼图 </a-button>
        <a-button type="primary" @click="batchDownload" :disabled="btnDisabled"> 下载 </a-button>
      </div>
    </a-drawer>

    <SaveImageLibModal ref="SaveImageLibModal" />
  </div>
</template>

<script>
import SaveImageLibModal from './SaveImageLibModal.vue';
import { Tool } from '@/utils/index.js';
import Cookies from 'js-cookie';
import { getTopDomain } from '@/utils/auth.js';
export default {
  components: {
    SaveImageLibModal,
  },
  data() {
    return {
      visible: false,
      picList: [],
      selectedPicList: [],
    };
  },
  computed: {
    btnDisabled() {
      return this.selectedPicList.length === 0;
    },
  },
  methods: {
    openDrawer(data) {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      this.picList = data;
    },
    handleClose() {
      this.visible = false;
    },
    checkPic(index) {
      const tempIndex = this.selectedPicList.indexOf(index);
      if (tempIndex >= 0) {
        this.selectedPicList.splice(tempIndex, 1);
      } else {
        this.selectedPicList.push(index);
      }
    },
    saveImageLib() {
      this.selectedPicList.sort();
      const imageInfoList = this.selectedPicList.map((index) => {
        let suffix = 'jpg';
        let url = this.picList[index].fileUrl;
        url = url.replace(process.env.VUE_APP_PIC_OSS_DOMAIN, process.env.VUE_APP_PIC_OSS_CDN_DOMAIN);
        if (url.includes('/jpg/')) {
          suffix = 'jpg';
        } else if (url.includes('/png/')) {
          suffix = 'png';
        }
        const title = Tool.uuid();
        const name = `${title}.${suffix}`;
        return {
          name,
          title,
          url,
        };
      });
      this.$refs.SaveImageLibModal.openModal(imageInfoList);
    },
    editPic() {
      this.selectedPicList.sort();
      const imageUrlList = this.selectedPicList.map((index) => {
        return this.picList[index].fileUrl.replace(
          process.env.VUE_APP_PIC_OSS_DOMAIN,
          process.env.VUE_APP_PIC_OSS_CDN_DOMAIN
        );
      });
      const topDomain = getTopDomain();
      Cookies.set('templateImageList', JSON.stringify(imageUrlList), { domain: topDomain });
      window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}/home?isTransferImage=true`, '_blank');
    },
    combinePic() {
      this.selectedPicList.sort();
      const imageUrlList = this.selectedPicList.map((index) => {
        return this.picList[index].fileUrl.replace(
          process.env.VUE_APP_PIC_OSS_DOMAIN,
          process.env.VUE_APP_PIC_OSS_CDN_DOMAIN
        );
      });
      const topDomain = getTopDomain();
      Cookies.set('templateImageList', JSON.stringify(imageUrlList), { domain: topDomain });
      window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}/home?tab=JigsawList&isTransferImage=true`, '_blank');
    },
    downloadImg(imgSrc, name, suffix) {
      //下载图片地址和图片名
      let image = new Image();
      image.setAttribute('referrerpolicy', 'no-referrer');
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL(`image/${suffix}`); //得到图片的base64编码数据'
        let a = document.createElement('a'); // 生成一个a元素
        let event = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgSrc.replace(process.env.VUE_APP_PIC_OSS_DOMAIN, process.env.VUE_APP_PIC_OSS_CDN_DOMAIN);
    },
    /**
     * 批量下载
     */
    batchDownload() {
      this.selectedPicList.sort();
      this.selectedPicList.forEach((index) => {
        let suffix = 'jpg';
        let url = this.picList[index].fileUrl;
        url = url.replace(process.env.VUE_APP_PIC_OSS_DOMAIN, process.env.VUE_APP_PIC_OSS_CDN_DOMAIN);
        if (url.includes('/jpg/')) {
          suffix = 'jpg';
        } else if (url.includes('/png/')) {
          suffix = 'png';
        }
        const title = Tool.uuid();
        const name = `${title}.${suffix}`;
        this.downloadImg(url, name, suffix);
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
::v-deep .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
::v-deep .ant-drawer-body {
  flex: 1;
  overflow-y: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.pic-list {
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 200px;
  grid-gap: 24px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  .img-wrapper {
    width: 150px;
    height: 200px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .check-box {
    position: absolute;
    right: 2px;
    top: 0;
  }
}

.footer-wrapper {
  border-top: 1px solid #e8e8e8;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
</style>
